@import url("https://fonts.googleapis.com/css?family=Open+Sans:700");
@property --offset {
    syntax: '<length>';
    inherits: false;
    initial-value: 0;
}

.hover-item:hover {
    color: #14b8a6;
}

.hover-item.active {
    color: #14b8a6;
    --offset: 1em;
    text-underline-offset: var(--offset, 1em);
    text-decoration: underline 2px;
    transition: --offset 400ms, text-decoration-color 400ms;
}