.services {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}

.cards {
    padding: 4rem;
    background: #fff;
}

.title {
    text-align: center;
}

.cards_container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
}

.cards_wrapper {
    position: relative;
    margin: 50px 0 45px;
}

.cards_items {
    margin-bottom: 24px;
}

.cards_item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
}

.cards_item_link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
}

.cards_item_pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.cards_item_pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #4ca8a1;
    box-sizing: border-box;
}

.cards_item_img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.cards_item_img:hover {
    transform: scale(1.1);
}

.cards_item_info {
    padding: 20px 30px 30px;
}

.cards_item_text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
}

@media only screen and (min-width: 1200px) {
    .content_blog_container {
        width: 84%;
    }
}

@media only screen and (min-width: 1024px) {
    .cards_items {
        display: flex;
    }
}

@media only screen and (max-width: 1024px) {
    .cards_item {
        margin-bottom: 2rem;
    }
}